import React from "react";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import { ReactComponent as TelegramIcon } from "../../../assets/telegram.svg";
import { ReactComponent as LinkedInIcon } from "../../../assets/linkedin.svg";

import TheBarChef from "../../../assets/TheBarChef.jpg";
import Moonberry from "../../../assets/suvi.jpeg";

import { withNamespaces } from "react-i18next";

const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: "60px",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "space-between",
    },
    fontFamily: "Montserrat",
  },
  headerText: {
    fontWeight: "bold",
    fontSize: "44px",
    fontFamily: "Montserrat",
    letterSpacing: "0px",
    color: "#FFFFFF",
    marginBottom: "140px",
  },
  membersContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    width: "100%",
  },
  memberWrapper: {
    marginLeft: "80px",
    marginRight: "80px",
    maxWidth: "400px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  link: {
    padding: "0 5px 0 5px",
    textDecoration: "none",
    "&:hover": {
      opacity: 0.8,
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0 20px 0 20px",
    },
  },
  icon: {
    color: "white",
  },
  memberName: {
    fontWeight: "normal",
    fontSize: "25px",
    color: "white",
    fontFamily: "Montserrat",
    marginBottom: "12px",
  },
  memberRole: {
    fontWeight: "normal",
    fontSize: "20px",
    color: "white",
    fontFamily: "Montserrat",
    marginBottom: "24px",
    fontStyle: "italic",
  },
  memberDescription: {
    textAlign: "center",
    fontWeight: "normal",
    fontSize: "18px",
    color: "white",
    fontFamily: "Montserrat",
    maxWidth: "400px",
    marginBottom: "50px",
  },
  memberImg: {
    borderRadius: "60px",
    marginBottom: "30px",
  },
});

const TeamSection = ({ classes }) => {
  return (
    <div className={classes.root}>
      <Typography variant="h1" className={classes.headerText}>
        TEAM
      </Typography>
      <div className={classes.membersContainer}>
        <div className={classes.memberWrapper}>
          <img
            className={classes.memberImg}
            src={TheBarChef}
            alt="logo"
            style={{ width: "335px", height: "335px" }}
          />
          <Typography className={classes.memberName}>
            @TheBarChef{" "}
            <a
              href="https://t.me/TheBarChef"
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <TelegramIcon
                className={classes.icon}
                style={{ width: "24px", height: "24px" }}
              />
            </a>
          </Typography>
          <Typography className={classes.memberRole}>Partner</Typography>
          <Typography className={classes.memberDescription}>
            A software entrepreneur with 20 years of experience conceptualizing,
            building and launching technology companies across several
            industries. At Starter Capital, he focuses on the viability, team
            and technical merits of our portfolio projects.
          </Typography>
        </div>
        <div className={classes.memberWrapper}>
          <img
            className={classes.memberImg}
            src={Moonberry}
            alt="logo"
            style={{ width: "335px", height: "335px" }}
          />
          <Typography className={classes.memberName}>
            Suvi Rinkinen{" "}
            <a
              href="https://www.linkedin.com/in/suvirinkinen/"
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedInIcon
                className={classes.icon}
                style={{ width: "24px", height: "24px" }}
              />
            </a>
          </Typography>
          <Typography className={classes.memberRole}>Partner</Typography>
          <Typography className={classes.memberDescription}>
            A pioneer and experienced business professional with over a decade
            of experience in marketing, operations and digital ledger
            technologies, Suvi is the COO of Starter International, Ltd and
            former CEO of the Telos Foundation.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(TeamSection)));
