import React from "react";
import { withRouter, useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  DialogContent,
  Dialog,
  Slide,
  IconButton,
  Typography,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    background: "#13161B",
    minWidth: "100%",
    minHeight: "100%",
    overflow: "hidden",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    [theme.breakpoints.up("sm")]: {
      alignItems: "flex-start",
      justifyContent: "flex-start",
      padding: "40px",
    },
  },
  closeButton: {
    position: "absolute",
    right: "16px",
    top: "16px",
    paddingTop: "0px",
  },
  linkContainer: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  menuLinkContainer: {
    outline: "none",
    textDecoration: "none",
  },
  menuLink: {
    color: theme?.colors?.intro?.menuLink,
    fontWeight: "bold",
    fontSize: "60px",
    [theme.breakpoints.up("ms")]: {
      fontSize: "80px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "120px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "162px",
    },
    opacity: 0.7,
    "&:hover": {
      opacity: 1,
    },
  },
});

const RedirectModal = ({ classes, closeModal, modalOpen }) => {
  const history = useHistory();
  const navUrl = history?.location?.pathname?.toLowerCase() || "";

  return (
    <Dialog
      open={modalOpen}
      onClose={closeModal}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      fullScreen={true}
    >
      <DialogContent classes={{ root: classes.root }}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={closeModal}
        >
          <CloseIcon
            style={{ color: "white", width: "32px", height: "32px" }}
          />
        </IconButton>
        <div className={classes.linkContainer}>
          <a
            href="/#/decree"
            className={classes.menuLinkContainer}
            onClick={() => {
              closeModal();
            }}
          >
            <Typography variant="h1" className={classes.menuLink}>
              DECREE
            </Typography>
          </a>
          <a
            href="/#/portfolio"
            className={classes.menuLinkContainer}
            onClick={() => {
              closeModal();
            }}
          >
            <Typography variant="h1" className={classes.menuLink}>
              PORTFOLIO
            </Typography>
          </a>
          <a
            href="/#/team"
            className={classes.menuLinkContainer}
            onClick={() => {
              closeModal();
            }}
          >
            <Typography variant="h1" className={classes.menuLink}>
              TEAM
            </Typography>
          </a>
          <a
            href="/#/contact"
            className={classes.menuLinkContainer}
            onClick={() => {
              closeModal();
            }}
          >
            <Typography variant="h1" className={classes.menuLink}>
              CONTACT
            </Typography>
          </a>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default withRouter(withStyles(styles)(RedirectModal));
