import React, { useEffect, useRef } from "react";
import { withRouter, useParams } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import { Fade } from "@material-ui/core";

import { FadeInWhenVisible } from "../animation";

import { withNamespaces } from "react-i18next";

import IntroSection from "./components/intro";
import DecreeSection from "./components/decree";
import PortfolioSection from "./components/portfolio";
import TeamSection from "./components/team";
import ContactSection from "./components/contact";

const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
    fontFamily: "Montserrat",
    position: "relative",
  },
  mainContainer: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  introContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme?.colors?.intro?.background,
    padding: "30px",
  },
  decreeContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background:
      "transparent linear-gradient(297deg, #FBE558 0%, #D94F43 100%) 0% 0% no-repeat padding-box",
    position: "relative",

    padding: "30px",
  },
  portfolioContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FEF8EB",
    position: "relative",
    padding: "30px",
  },
  teamContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#252628",
    position: "relative",
    padding: "30px",
  },
  contactContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FEF8EB",
    position: "relative",
    padding: "30px",
  },
});

const Home = ({ classes, location }) => {
  const { section } = useParams();
  const decreeRef = useRef(null);
  const portfolioRef = useRef(null);
  const teamRef = useRef(null);
  const contactRef = useRef(null);

  useEffect(() => {
    if (section === "decree") {
      decreeRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    } else if (section === "portfolio") {
      portfolioRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    } else if (section === "team") {
      teamRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    } else if (section === "contact") {
      contactRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [section]);

  return (
    <div className={classes.root}>
      <Fade in={true} timeout={{ enter: 1000 }}>
        <div className={classes.mainContainer}>
          <div className={classes.introContainer}>
            <IntroSection />
          </div>
          <div ref={decreeRef} className={classes.decreeContainer}>
            <DecreeSection />
          </div>
          <div ref={portfolioRef} className={classes.portfolioContainer}>
            <PortfolioSection />
          </div>
          <div ref={teamRef} className={classes.teamContainer}>
            <TeamSection />
          </div>
          <div ref={contactRef} className={classes.contactContainer}>
            <ContactSection />
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(Home)));
