import React, { useState } from "react";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import { Typography, IconButton } from "@material-ui/core";

import { withNamespaces } from "react-i18next";
import Fomo2Labs from "../../../assets/fomo.png";
import WsbLogo from "../../../assets/wsb.png";
import BSCStarterLogo from "../../../assets/bscstarter-logo-normal.png";
import STARTVestLogo from "../../../assets/vest_white.png";
import WISELogo from "../../../assets/wise.svg";
import CakeLogo from "../../../assets/cake.png";
import YampLogo from "../../../assets/yamp.png";
import RelayLogo from "../../../assets/relay_chain.jpeg";

import PortfolioModal from "./modal";

const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: "60px",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "space-between",
    },
    fontFamily: "Montserrat",
  },
  headerText: {
    fontWeight: "bold",
    fontSize: "44px",
    fontFamily: "Montserrat",
    letterSpacing: "0px",
    color: "#EC513B",
    marginBottom: "48px",
  },
  portfoliosContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  logoButton: {
    marginLeft: "40px",
    marginRight: "40px",
    width: "369px",
    height: "369px",
    borderRadius: "60px",
    backgroundColor: "#FEF8EB",
    "&:hover": {
      backgroundColor: "#facda7",
    },
  },
});

const PortfolioSection = ({ classes }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [isModalOpen, showModal] = useState(false);
  const handleClick = (portfolio) => {
    if (portfolio === 0) {
      setTitle("Fomo Labs");
      setDescription(
        "$FOMO - Fomo Lab is the most exclusive NFT management agency and marketplace on the Binance Smart Chain. They are at the very edge of the NFT trend and we are committed to building an integral infrastructure to support the NFT ecosystem as a whole and the Fomo Lab Project."
      );
      setUrl("https://www.fomolab.io/");
    } else if (portfolio === 1) {
      setTitle("WSB");
      setDescription(
        "$WSB - WSB DApp is a community-owned and operated decentralized version of r/wallstreetbets. WSB DAO was created to openly encourage the free flow of public information and opinion into actionable results. So that anyone, everywhere can YOLO together as one. It’s time to fully decentralize Wall Street."
      );
      setUrl("https://www.wsbdapp.com/");
    } else if (portfolio === 2) {
      setTitle("Starter.xyz");
      setDescription(
        "$START - The #1 and most active community-oriented token presale platform for #BinanceSmartChain #Ethereum #Cardano #Solana #Avalanche #Polygon"
      );
      setUrl("https://starter.xyz/#/home");
    } else if (portfolio === 3) {
      setTitle("VEST");
      setDescription(
        "$VEST - Liquidity locking and team token vesting solution for #BinanceSmartChain #Ethereum #Cardano #Solana #Avalanche #Polygon. With START Vesting, intrepid crypto pioneers finally have a solid tool to tell the promising projects from the putrid; the creators from the criminals."
      );
      setUrl("https://startvesting.xyz/");
    } else if (portfolio === 4) {
      setTitle("WISE");
      setDescription(
        "$WISE - WISE is an asset-backed cryptocurrency designed to be a highly secure store of value. WISE products include staking, lending/borrowing, and liquidity providing."
      );
      setUrl("https://wisetoken.net/bsc");
    } else if (portfolio === 5) {
      setTitle("CAKE");
      setDescription(
        "$CAKE - A hyper deflationary and elastic supply token that has unique reserve and reward mechanisms featuring an automated hybrid monetary policy."
      );
      setUrl("https://cake.monster/");
    } else if (portfolio === 6) {
      setTitle("Yamp Finance");
      setDescription(
        "$YAMP - Yamp is an LP token leverage protocol built for Matic, BSC, ETH and SOL. Leverage your LP tokens across all blockchains in one place."
      );
      setUrl("https://yamp.finance/");
    } else if (portfolio === 7) {
      setTitle("Relay Chain");
      setDescription(
        "$RELAY - Relay Chain is a Bridging as a Service (BaaS) Provider for DeFi Ecosystems: $RELAY $ETH $AVAX $MATIC $BSC and soon more."
      );
      setUrl("https://www.relaychain.com/");
    }
    showModal(true);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h1" className={classes.headerText}>
        PORTFOLIO
      </Typography>
      <div className={classes.portfoliosContainer}>
        <IconButton
          aria-label="logo"
          classes={{ root: classes.logoButton }}
          onClick={() => {
            handleClick(0);
          }}
        >
          <img
            src={Fomo2Labs}
            alt="logo"
            style={{ width: "183px", height: "183px" }}
          />
        </IconButton>
        <IconButton
          aria-label="logo"
          classes={{ root: classes.logoButton }}
          onClick={() => {
            handleClick(1);
          }}
        >
          <img
            src={WsbLogo}
            alt="logo"
            style={{ width: "183px", height: "183px" }}
          />
        </IconButton>
        <IconButton
          aria-label="logo"
          classes={{ root: classes.logoButton }}
          onClick={() => {
            handleClick(2);
          }}
        >
          <img
            src={BSCStarterLogo}
            alt="logo"
            style={{ width: "183px", height: "183px" }}
          />
        </IconButton>
        <IconButton
          aria-label="logo"
          classes={{ root: classes.logoButton }}
          onClick={() => {
            handleClick(3);
          }}
        >
          <img src={STARTVestLogo} alt="logo" style={{ width: "183px" }} />
        </IconButton>
        <IconButton
          aria-label="logo"
          classes={{ root: classes.logoButton }}
          onClick={() => {
            handleClick(4);
          }}
        >
          <img
            src={WISELogo}
            alt="logo"
            style={{ width: "183px", height: "183px" }}
          />
        </IconButton>
        <IconButton
          aria-label="logo"
          classes={{ root: classes.logoButton }}
          onClick={() => {
            handleClick(5);
          }}
        >
          <img src={CakeLogo} alt="logo" style={{ width: "183px" }} />
        </IconButton>
        <IconButton
          aria-label="logo"
          classes={{ root: classes.logoButton }}
          onClick={() => {
            handleClick(6);
          }}
        >
          <img src={YampLogo} alt="logo" style={{ width: "183px" }} />
        </IconButton>
        <IconButton
          aria-label="logo"
          classes={{ root: classes.logoButton }}
          onClick={() => {
            handleClick(7);
          }}
        >
          <img
            src={RelayLogo}
            alt="logo"
            style={{ width: "183px", borderRadius: "100px" }}
          />
        </IconButton>
      </div>
      <PortfolioModal
        modalOpen={isModalOpen}
        closeModal={() => {
          showModal(false);
        }}
        title={title}
        description={description}
        url={url}
      />
    </div>
  );
};

export default withNamespaces()(
  withRouter(withStyles(styles)(PortfolioSection))
);
