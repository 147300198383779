import React from "react";
import { useHistory, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CapitalLogo from "../../assets/bscstarter-logo-normal.png";
import RedirectModal from "./modal";

const styles = (theme) => ({
  root: {
    position: "sticky",
    top: 0,
    zIndex: 5,
    display: "flex",
    width: "100%",
    height: "60px",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme?.colors?.header?.background,
    overflow: "hidden",
  },
  headerContainer: {
    display: "flex",
    maxWidth: "1460px",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "0px 32px",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
  },
  openMenuButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logoText: {
    color: theme?.colors?.header?.text,
    letterSpacing: "0px",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: "600",
  },
  logoBoldText: {
    color: theme?.colors?.header?.boldBrownText,
    letterSpacing: "0px",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: "600",
  },
  menuButton: {
    padding: "6px",
  },
  menuIcon: {
    width: "24px",
    height: "24px",
    color: theme?.colors?.header?.menuButton,
  },
});

function Header({ classes }) {
  const history = useHistory();
  const navUrl = history?.location?.pathname?.toLowerCase() || "";
  const nav = (screen) => {
    history.push("/" + screen);
  };
  const [modalOpen, setModalOpen] = React.useState(false);
  const closeNavModal = () => {
    setModalOpen(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <div className={classes.logoContainer}>
          <IconButton
            aria-label="logo"
            classes={{ root: classes.logoButton }}
            onClick={() => {
              nav("");
            }}
          >
            <img
              src={CapitalLogo}
              alt="logo"
              style={{ width: "40px", height: "40px", marginRight: "8px" }}
            />
            <span className={classes.logoText}>
              Starter
              <span className={classes.logoBoldText}>&nbsp;Capital</span>
            </span>
          </IconButton>
        </div>
        <div className={classes.openMenuButtonContainer}>
          <IconButton
            aria-label="menu"
            classes={{ root: classes.menuButton }}
            onClick={() => {
              nav("");
              setModalOpen(true);
            }}
          >
            <MenuIcon className={classes.menuIcon} />
          </IconButton>
        </div>
      </div>
      <RedirectModal closeModal={closeNavModal} modalOpen={modalOpen} />
    </div>
  );
}

export default withRouter(withStyles(styles)(Header));
