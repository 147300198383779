import React from "react";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import { withNamespaces } from "react-i18next";
import DecreeBg from "../../../assets/decree-bg.png";

const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    width: "100%",
    maxWidth: "1460px",
    height: "100%",
    justifyContent: "center",
    alignItems: "flex-end",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "space-between",
    },
    fontFamily: "Montserrat",
    paddingTop: "40px",
  },
  decreeList: {
    display: "flex",
    flexDirection: "column",
    zIndex: 2,
  },
  headerText: {
    fontWeight: "bold",
    fontSize: "44px",
    fontFamily: "Montserrat",
    letterSpacing: "0px",
    color: "#252628",
    marginBottom: "70px",
  },
  subHeader: {
    fontWeight: "normal",
    fontSize: "25px",
    fontFamily: "Montserrat",
    letterSpacing: "0px",
    color: "#252628",
    marginBottom: "24px",
    maxWidth: "644px",
  },
  subText: {
    fontWeight: "normal",
    fontSize: "18px",
    fontFamily: "Montserrat",
    letterSpacing: "0px",
    color: "#252628",
    maxWidth: "644px",
  },
  boldText: {
    fontWeight: "bold",
    fontSize: "30px",
    fontFamily: "Montserrat",
    letterSpacing: "0px",
    color: "#252628",
    marginBottom: "24px",
    maxWidth: "644px",
  },
  decreeImage: {
    position: "absolute",
    top: "0px",
    right: "0px",
    maxWidth: "748px",
    height: "100%",
    display: "none",
    alignItems: "center",
    justifyContent: "center",
    objectFit: "cover",
    zIndex: 1,
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
  },
});

const DecreeSection = ({ classes }) => {
  return (
    <div className={classes.root}>
      <div className={classes.decreeList}>
        <Typography variant="h1" className={classes.headerText}>
          DECREE
        </Typography>
        <Typography variant="h1" className={classes.subHeader}>
          Blockchain and Digital Currency are The Future
        </Typography>
        <Typography
          variant="h1"
          className={classes.boldText}
          styles={{ marginBottom: "70px" }}
        >
          Helping projects recreate the future by providing access to investors,
          marketing support, and starting capital.
        </Typography>
        <Typography variant="h1" className={classes.subHeader}>
          What We Look For
        </Typography>
        <Typography
          variant="h1"
          className={classes.subText}
          style={{ marginBottom: "70px" }}
        >
          We are excited about the potential of blockchain technology and
          cryptocurrency, and are exclusively focused on projects innovating on
          top of digital ledger technologies.
        </Typography>
        <Typography variant="h1" className={classes.subHeader}>
          What We Offer
        </Typography>
        <Typography
          variant="h1"
          className={classes.subText}
          style={{ marginBottom: "70px" }}
        >
          Our team has extensive experience in blockchain, marketing, business
          development and traditional industries. We help position our portfolio
          projects for success by offering 360-degree support.
        </Typography>
      </div>
      <img className={classes.decreeImage} src={DecreeBg} alt="decree-bg" />
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(DecreeSection)));
