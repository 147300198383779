import React, { useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Switch, HashRouter, Route, Redirect } from "react-router-dom";

import "./i18n";
import { lightTheme, darkTheme } from "./theme";

import TopBar from "./components/header";
import Home from "./components/home";

import { init } from "emailjs-com";

function App() {
  useEffect(() => {
    init("user_O9NWMfFTctIMMAmSjgO8S");
  }, []);

  return (
    <HashRouter>
      <MuiThemeProvider theme={createMuiTheme(lightTheme)}>
        <CssBaseline />
        <TopBar />
        <Switch>
          <Route path="/:section">
            <Home />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
          <Redirect to="/" />
        </Switch>
      </MuiThemeProvider>
    </HashRouter>
  );
}

export default App;
