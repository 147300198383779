import React from "react";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import { withNamespaces } from "react-i18next";

const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    width: "100%",
    maxWidth: "1460px",
    height: "100%",
    justifyContent: "center",
    alignItems: "flex-end",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "space-between",
    },
    fontFamily: "Montserrat",
    position: "relative",
  },
  menuList: {
    display: "none",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  menuLinkContainer: {
    outline: "none",
    textDecoration: "none",
  },
  menuLink: {
    color: theme?.colors?.intro?.menuLink,
    fontWeight: "bold",
    [theme.breakpoints.up("ms")]: {
      fontSize: "80px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "120px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "162px",
    },
    opacity: 0.7,
    "&:hover": {
      opacity: 1,
    },
  },
  welcomePart: {
    minWidth: "250px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    [theme.breakpoints.up("md")]: {
      marginLeft: "33px",
    },
  },
  divLine: {
    border: "solid 3px white",
    width: "169px",
    height: "0px",
    marginBottom: "30px",
  },
  welcomeText: {
    fontSize: "30px",
    fontFamily: "Montserrat",
    letterSpacing: "0px",
    textAlign: "left",
    lineHeight: "37px",
    fontWeight: "normal",
    color: "white",
    marginBottom: "16px",
  },
  welcomeBoldText: {
    fontSize: "30px",
    fontFamily: "Montserrat",
    letterSpacing: "0px",
    textAlign: "left",
    lineHeight: "37px",
    fontWeight: "normal",
    color: "#F48D3F",
  },
  welcomeDescription: {
    textAlign: "left",
    fontSize: "25px",
    lineHeight: "40px",
    fontFamily: "Montserrat",
    letterSpacing: "0px",
    color: "#777B84",
    marginBottom: "50px",
  },
});

const IntroSection = ({ classes }) => {
  return (
    <div className={classes.root}>
      <div className={classes.menuList}>
        <a href="/#/decree" className={classes.menuLinkContainer}>
          <Typography variant="h1" className={classes.menuLink}>
            DECREE
          </Typography>
        </a>
        <a href="/#/portfolio" className={classes.menuLinkContainer}>
          <Typography variant="h1" className={classes.menuLink}>
            PORTFOLIO
          </Typography>
        </a>
        <a href="/#/team" className={classes.menuLinkContainer}>
          <Typography variant="h1" className={classes.menuLink}>
            TEAM
          </Typography>
        </a>
        <a href="/#/contact" className={classes.menuLinkContainer}>
          <Typography variant="h1" className={classes.menuLink}>
            CONTACT
          </Typography>
        </a>
      </div>
      <div className={classes.welcomePart}>
        <div className={classes.divLine} />
        <span className={classes.welcomeText}>
          Welcome to
          <span className={classes.welcomeBoldText}>&nbsp;Starter Capital</span>
        </span>
        <span className={classes.welcomeDescription}>
          Empowering blockchain and digital currency innovators with resources
          and access to capital to help create a new future.
        </span>
      </div>
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(IntroSection)));
