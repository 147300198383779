import React, { useState } from "react";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import { Typography, Input, TextField, Button } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { withNamespaces } from "react-i18next";

import emailjs from "emailjs-com";

const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    [theme.breakpoints.up("md")]: {
      justifyContent: "space-between",
      padding: "60px",
    },
    fontFamily: "Montserrat",
    position: "relative",
  },
  headerText: {
    fontWeight: "bold",
    fontSize: "44px",
    fontFamily: "Montserrat",
    letterSpacing: "0px",
    color: "#252628",
    marginBottom: "96px",
  },
  contactContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    maxWidth: "800px",
  },
  logoButton: {
    marginLeft: "40px",
    marginRight: "40px",
    width: "369px",
    height: "369px",
    borderRadius: "60px",
    backgroundColor: "#FEF8EB",
    "&:hover": {
      backgroundColor: "#facda7",
    },
  },
  poolInfoInput: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: "40px",
  },
  poolInfoTextInput: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  poolInfoInputHeader: {
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: "normal",
    fontFamily: "Montserrat",
    letterSpacing: "0px",
    color: "#252628",
    marginBottom: "8px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "16px",
    },
  },

  nameAndEmail: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      "& > *": {
        "&:first-child": {
          marginRight: "20px",
        },
        "&:last-child": {
          marginLeft: "20px",
        },
      },
    },
  },

  actionInput: {
    border: "solid 1px #252628",
    backgroundColor: "white",
    borderRadius: "14px",
    padding: "8px",
    height: "50px",
    width: "100%",
    color: "black",
    outline: "0px",
  },
  actionTextInput: {
    border: "solid 1px #252628",
    borderRadius: "14px",
    padding: "8px",
    height: "250px",
    width: "100%",
    color: "black",
    backgroundColor: "white",

    outline: "0px",
    overflow: "auto",
    "& > div > fieldset ": {
      borderWidth: "0px !important",
      borderColor: "transparent !important",
    },
    "& > div > textarea ": {
      color: "black",
    },
  },
  actionUnderline: {
    color: "black",
  },
  actionButton: {
    borderRadius: "15px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: "#EA413A",
    color: "#000000",
    textTransform: "capitalize",
    height: "40px",
    width: "230px",
    padding: "8px 16px",
    opacity: "0.6",
    marginTop: "30px",
    "&:hover": {
      backgroundColor: "#EA413A",
      color: "#000000",
      opacity: "1",
    },
  },
  buttonLabel: {
    fontWeight: "bold",
    fontSize: "15px",
    lineHeight: "19px",
    fontFamily: "Montserrat",
  },
  scrollToTop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    marginTop: "30px",
    right: "-30px",
    bottom: "-10px",
    position: "absolute",
  },
  scrollToTopButton: {
    width: "200px",
  },
  scrollToTopButtonLabel: {
    fontWeight: "normal",
    fontSize: "13px",
    fontFamily: "Montserrat",
    textTransform: "none",
  },
  responseText: {
    marginTop: "5px",
    fontSize: "12px",
    lineHeight: "15px",
    fontWeight: "normal",
    fontFamily: "Montserrat",
  },
});

const ContactSection = ({ classes }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [whitepaper, setWhitepaper] = useState("");
  const [pitch, setPitch] = useState("");

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [whitepaperError, setWhitepaperError] = useState("");
  const [pitchError, setPitchError] = useState("");

  const [responseText, setResponseText] = useState("");
  const [responseCode, setResponseCode] = useState(false);

  const renderInputBox = (title, value, onSet, error) => {
    return (
      <div className={classes.poolInfoInput}>
        <Typography variant="h4" className={classes.poolInfoInputHeader}>
          {title}
        </Typography>
        <Input
          classes={{
            root: classes.actionInput,
            underline: classes.actionUnderline,
          }}
          disableUnderline={true}
          value={value}
          onChange={(ev) => {
            onSet(ev.target.value);
          }}
        />
        {error && (
          <Typography variant="h6" className={classes.errorText}>
            {error}
          </Typography>
        )}
      </div>
    );
  };

  const renderTextBox = (title, value, onSet, error) => {
    return (
      <div className={classes.poolInfoTextInput}>
        <Typography variant="h4" className={classes.poolInfoInputHeader}>
          {title}
        </Typography>
        <TextField
          id="outlined-multiline-static"
          classes={{
            root: classes.actionTextInput,
          }}
          multiline={true}
          disableUnderline={true}
          defaultValue={value}
          variant="outlined"
          onChange={(ev) => {
            onSet(ev.target.value);
          }}
        />
        {error && (
          <Typography variant="h6" className={classes.errorText}>
            {error}
          </Typography>
        )}
      </div>
    );
  };

  const onSubmit = () => {
    if (!name) {
      setNameError("Invalid name.");
      return;
    }
    if (!email) {
      setEmailError("Invalid email.");
      return;
    }
    if (!whitepaper) {
      setWhitepaperError("Invalid whitepaper.");
      return;
    }

    const params = { name, email, whitepaper, pitch };
    emailjs
      .send(
        "service_ubthvvd",
        "template_1ysgwrp",
        params,
        "user_O9NWMfFTctIMMAmSjgO8S"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setResponseText("Successfully sent. Thank you!");
          setResponseCode(true);
        },
        (err) => {
          console.log("FAILED...", err);
          setResponseText("Failed...", err);
          setResponseCode(false);
        }
      );
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={classes.root}>
      <Typography variant="h1" className={classes.headerText}>
        CONTACT
      </Typography>
      <div className={classes.contactContainer}>
        <div className={classes.nameAndEmail}>
          {renderInputBox("Your Name", name, setName, nameError)}
          {renderInputBox("Your Email", email, setEmail, emailError)}
        </div>
        {renderInputBox(
          "Link To Whitepaper",
          whitepaper,
          setWhitepaper,
          whitepaperError
        )}
        {renderTextBox("Elevator Pitch", pitch, setPitch, pitchError)}
        {responseText && (
          <div
            className={classes.responseText}
            style={{ color: responseCode ? "green" : "red" }}
          >
            {responseText}
          </div>
        )}
        <Button
          classes={{
            root: classes.actionButton,
            label: classes.buttonLabel,
          }}
          onClick={() => {
            onSubmit();
          }}
        >
          Submit
        </Button>
      </div>
      <div className={classes.scrollToTop}>
        <Button
          classes={{
            root: classes.scrollToTopButton,
            label: classes.scrollToTopButtonLabel,
          }}
          onClick={() => {
            scrollToTop();
          }}
        >
          Scroll to top <ExpandLessIcon />
        </Button>
      </div>
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(ContactSection)));
