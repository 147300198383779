import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

export const colors = {
  white: "#fff",
  black: "#000",
  blue: "#2F80ED",
  red: "#e02424",
  green: "#1abc9c",
  orange: "#ffa500",
  pink: "#DC6BE5",
  tomato: "#e56b73",
  purple: "#935dff",

  darkGreen: "#27652f",
  darkBlack: "#252628",
  darkRedPrimary: "#EC513B",
  darkRedSecondary: "#471C23",

  lightPumpkin: "#FD9B40",
  lightPumpkinSecondary: "#F58A1F",

  lightYellowPrimary: "#F48D3F",
  lightYellowPrimaryHover: "#ae8501",
  lightYellowThird: "rgba(238, 203, 112, 0.3)",

  lightBlackPrimary: "#13161B",
  lightBlackSecondary: "#6a6a6a",

  brownPrimary: "#D1884F",
  blackPrimary: "#1E232C",

  lightGrayPrimary: "#EDEDED",
  lightGrayPrimaryTrans: "#ededed7a",
  lightGraySecondary: "#A1A1A4",
  lightGrayThird: "#2A2F38",
  lightGrayFourth: "#707070",

  lightGreenPrimary: "#00C76B",
  lightGreenSecondary: "#184539",
  lightGreenThird: "#1FF582",

  lightRedPrimary: "#E80000",
  lightRedSecondary: "#de5656",

  lightBluePrimary: "#00C2F0",
  lightBlueSecondary: "#3a709e",
  lightBlueThird: "#1FABF5",
  lightBlueFourth: "#112b36",
};

const breakpoints = createBreakpoints({
  keys: ["xs", "sm", "ms", "md", "lg", "xl"],
  values: {
    xs: 0,
    sm: 600,
    ms: 768,
    md: 900,
    lg: 1200,
    xl: 1800,
  },
});

const lightTheme = {
  type: "light",
  colors: {
    header: {
      background: colors.darkBlack,
      text: colors.white,
      link: colors.white,
      linkHover: colors.lightPumpkin,
      menuButton: colors.white,
      boldBrownText: colors.lightYellowPrimary,
      balanceText: colors.lightGraySecondary,
      balanceValue: colors.lightYellowPrimary,
      heading: colors.lightGrayPrimary,
      textSecondary: colors.lightGrayPrimary,
    },
    intro: {
      menuLink: colors.darkRedPrimary,
      background: colors.darkBlack,
    },
    footer: {
      background: colors.blackPrimary,
      text: colors.lightGrayPrimary,
      textSecondary: colors.lightGraySecondary,
      icon: colors.lightYellowPrimary,
    },
    home: {
      heading: colors.white,
      headingSecondary: colors.lightYellowPrimary,
      text: colors.lightGrayPrimary,
      textSecondary: colors.lightGraySecondary,
      background: colors.blackPrimary,
      buttonText: colors.white,
    },
    dollarHighlight: colors.lightBlueSecondary,
    divider: colors.lightGraySecondary,
  },
  breakpoints: breakpoints,
};

const darkTheme = {
  type: "dark",
  colors: {},
  breakpoints: breakpoints,
};

export { lightTheme, darkTheme };
