import React from "react";
import { withRouter, useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  DialogContent,
  Dialog,
  Slide,
  IconButton,
  Button,
  Typography,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    background: "#FFFFFF",
    minWidth: "100%",
    minHeight: "100%",
    overflow: "hidden",
    position: "relative",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "50px",
    borderRadius: "60px",
  },
  closeButton: {
    position: "absolute",
    top: "40px",
    right: "40px",
    padding: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    marginTop: "30px",
    fontWeight: "normal",
    fontSize: "25px",
    fontFamily: "Montserrat",
    color: "#252628",
    marginBottom: "24px",
  },
  description: {
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "20px",
    fontFamily: "Montserrat",
    color: "#252628",
    marginBottom: "24px",
  },

  visitButton: {
    backgroundColor: "#EA413A",
    borderRadius: "15px",
    width: "150px",
    height: "40px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#EA413A",
      opacity: "0.7",
    },
  },
  buttonLabel: {
    fontWeight: "600",
    fontSize: "15px",
    fontFamily: "Montserrat",
    color: "#ffffff",
  },
  paper: {
    borderRadius: "60px",
  },
});

const PortfolioModal = ({
  classes,
  closeModal,
  modalOpen,
  title,
  description,
  url,
}) => {
  const history = useHistory();
  const navUrl = history?.location?.pathname?.toLowerCase() || "";
  const fullScreen = window.innerWidth < 900;

  return (
    <Dialog
      open={modalOpen}
      onClose={closeModal}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      classes={!fullScreen ? { paper: classes.paper } : {}}
    >
      <DialogContent classes={{ root: classes.root }}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={closeModal}
        >
          <CloseIcon
            style={{ color: "#000000", width: "32px", height: "32px" }}
          />
        </IconButton>
        <Typography variant="h1" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="h1" className={classes.description}>
          {description}
        </Typography>
        <Button
          classes={{ root: classes.visitButton, label: classes.buttonLabel }}
          onClick={() => {
            window.open(url, "_blank");
          }}
        >
          Visit Website
        </Button>
      </DialogContent>
    </Dialog>
  );
};
export default withRouter(withStyles(styles)(PortfolioModal));
